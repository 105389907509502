import React, { Component, Fragment } from "react";
import TopBanner from "../components/TopBanner/TopBanner";
import Services from "../components/Services/Services";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Analysis from "../components/Analysis/Analysis";
import Summary from "../components/Summary/Summary";
import RecentProject from "../components/RecentProject/RecentProject";
// import Plans from '../components/PlansDetails/PlansDetails';
import Video from "../components/Video/Video";
import ClientReview from "../components/ClientReview/ClientReview";

import Footer from "../components/Footer/Footer";
import Welcome from "../components/Welcome/Welcome";

class HomePage extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Fragment>
        <TopNavigation title="JMFG Starlink Internet Solution Provider" />
        <TopBanner />
        <Welcome />
        <Services />
        <Analysis />
        <Summary />
        <RecentProject />
        {/* <Plans /> */}
        <Video />
        <ClientReview />

        <Footer />
      </Fragment>
    );
  }
}

export default HomePage;
