import React, { Component, Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import pageone from "../../asset/image/p1.jpg";
import pagetwo from "../../asset/image/p2.jpg";
import pagethree from "../../asset/image/p3.jpg";
import Jump from "react-reveal/Jump";
import Slide from "react-reveal/Slide";
import Flip from "react-reveal/Flip";

class Welcome extends Component {
  render() {
    return (
      <Fragment>
        <div className="intro-area--top">
          <Container>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <div className="section-title text-center">
                  <div className="intro-area-inner">
                    <h6 className="sub-title double-line">WELCOME</h6>
                    <Jump>
                      <h2 className="maintitle">
                        JMFG<br></br>
                        STARLINK
                      </h2>
                    </Jump>
                    <Container className="text-center mt-5">
                      <Row>
                        <Col lg={4} md={6} sm={12}>
                          <Slide top>
                            <img src={pageone} style={{ width: 250 }} />
                          </Slide>
                          <Flip top>
                            <h1 className="serviceName">
                              High-Speed Fiber Internet{" "}
                            </h1>
                            <p className="serviceDescription text-justify">
                              Fiber internet solution provider, offering
                              high-speed internet connections for both
                              residential and business customers. You will
                              highlight the benefits of fiber internet,
                              including faster download and upload speeds, lower
                              latency, and increased reliability.
                            </p>
                          </Flip>
                        </Col>

                        <Col lg={4} md={6} sm={12}>
                          <Slide top>
                            <img src={pagetwo} style={{ width: 250 }} />
                          </Slide>
                          <Flip top>
                            <h1 className="serviceName">
                              Reliable Connections
                            </h1>
                            <p className="serviceDescription text-justify">
                              Fiber internet connections, highlighting your use
                              of advanced technology to ensure that your
                              customers experience minimal downtime or service
                              interruptions. You will also offer 24/7 support to
                              quickly resolve any issues that do arise.
                            </p>
                          </Flip>
                        </Col>

                        <Col lg={4} md={6} sm={12}>
                          <Slide top>
                            <img src={pagethree} style={{ width: 250 }} />
                          </Slide>
                          <Flip top>
                            <h1 className="serviceName">Customized Plans </h1>
                            <p className="serviceDescription text-justify">
                              Customized plans tailored to the specific needs of
                              each customer. This could include different speed
                              tiers, bandwidth options, and pricing plans to
                              ensure that customers have access to the right
                              plan to meet their needs and budget. You will also
                              highlight any additional features or perks that
                              come with your plans, such as free installation or
                              Wi-Fi routers.
                            </p>
                          </Flip>
                        </Col>
                      </Row>

                      {/* // Intro Footer Start  */}

                      <Row className="intro-footer bg-base text-center mt-5">
                        <Col lg={4} md={6} sm={12}>
                          <Row>
                            <Col lg={6} md={6} sm={12}>
                              {/* <img className="sideImg" src={} /> */}
                              <i className="fas fa-globe"></i>
                            </Col>

                            <Col lg={6} md={6} sm={12}>
                              <h5 className="text-justify homeIntro">
                                High-Speed Fiber Internet
                              </h5>
                              <p className="text-justify serviceDescription">
                                {/* serviceDescription */}
                              </p>
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={4} md={6} sm={12}>
                          <Row>
                            <Col lg={6} md={6} sm={12}>
                              {/* <img className="sideImg" src={imgtwo} /> */}
                              <i className="fas fa-wifi"></i>
                            </Col>

                            <Col lg={6} md={6} sm={12}>
                              <h5 className="text-justify homeIntro">
                                Reliable Connections
                              </h5>
                              <p className="text-justify serviceDescription">
                                {/* serviceDescription */}
                              </p>
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={4} md={6} sm={12}>
                          <Row>
                            <Col lg={6} md={6} sm={12}>
                              {/* <img className="sideImg" src={} /> */}
                              <i className="fas fa-list"></i>
                            </Col>

                            <Col lg={6} md={6} sm={12}>
                              <h5 className="text-justify homeIntro">
                                Customized Plans
                              </h5>
                              <p className="text-justify serviceDescription">
                                {/* serviceDescription */}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }
}

export default Welcome;
